<template>
  <q-page class="flex flex-center">
    <img alt="Quasar logo" src="../assets/logo.svg" style="width: 200px; height: 200px">
    <p>{{payload}}</p>
  </q-page>
</template>

<style>
</style>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'Home',
  data () {
    return {
      payload: {},
    }
  },
  setup () {
    // Need reload (to correctly apply token)
    const route = useRoute()
    if (route.query.needReload) {
      window.location.replace('/')
    }
  },
  mounted () {
    const token = localStorage.getItem('token')
    this.payload = JSON.parse(window.atob(token.split('.')[1]));
  }
}
</script>
