import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

function requireAuth(to, from, next) {
  // Cookie not set, redirect to auth
  if (!(localStorage.getItem('token'))) {
    next('/auth')
  } else {
    next()
  }
}

// Include requireAuth and check admin role
function requireAdmin(to, from, next) {
  const token = localStorage.getItem('token')
  // Cookie not set, redirect to auth
  if (!token) {
    next('/auth')
  } else {
    // Check if user is admin
    const payload = JSON.parse(window.atob(token.split('.')[1]));
    const roles = payload.role.map(r => r[0].toUpperCase() + r.slice(1))
    if (roles.includes('Admin')) {
      next()
    } else {
      next('/')
    }
  }
}

function getToken(to, from, next) {
  if (to.query.token) {
    // TODO : Do a regex test
    // Set cookie
    localStorage.setItem('token', to.query.token)
  }

  // Cookie set, redirect to home
  if (localStorage.getItem('token')) {
    next('/?needReload=true')
  } else {
    // if cookie not set show connection (redirect to /auth)
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireAuth
  },
  {
    path: '/student-list',
    name: 'StudentList',
    // LazyLoad
    component: () => import('../views/StudentList.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/student-details/:id',
    name: 'StudentDetails',
    // LazyLoad
    component: () => import('../views/StudentDetails.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/ged',
    name: 'GED',
    // LazyLoad
    component: () => import('../views/GED.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/admin',
    name: 'Admin',
    // LazyLoad
    component: () => import('../views/Admin.vue'),
    beforeEnter: requireAdmin
  },
  {
    path: '/auth',
    name: 'Authentification',
    // LazyLoad
    component: () => import('../views/Authentification.vue'),
    beforeEnter: getToken
  },
  {
    // 404
    path: '/:catchAll(.*)',
    name: '404',
    // LazyLoad
    component: () => import('../views/PageNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
