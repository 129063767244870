<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="">
      <q-toolbar class="bg-green">
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title>
          ESIEA ERP
        </q-toolbar-title>

        <q-separator dark vertical inset v-if="name != ''" />
        <div class="q-pa-md" v-if="name != ''">
          Connecté en tant que {{ name }}
        </div>
        <q-separator dark vertical inset v-if="roles.length > 0"/>
        <div class="q-pa-md" v-if="roles.length > 0">
          Roles : {{ roles.join(', ') }}
        </div>
        <q-btn color="red" label="Disconnect" v-if="name != ''" @click="disconnect()" />
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      bordered
      class="bg-grey-2"
    >
      <q-list>
        <q-item-label header>Menu</q-item-label>
        <router-link to="/" class="router-link">
        <q-item clickable tag="a">
          <q-item-section avatar>
            <q-icon name="home" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Accueil</q-item-label>
          </q-item-section>
        </q-item>
        </router-link>
        <div v-if="connected">
          <router-link to="/admin" class="router-link" v-if="roles.includes('Admin')">
          <q-item clickable tag="a">
            <q-item-section avatar>
              <q-icon name="admin_panel_settings" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Administration</q-item-label>
              <q-item-label caption>Gestion des référentiels</q-item-label>
            </q-item-section>
          </q-item>
          </router-link>
          <router-link to="/student-list" class="router-link">
          <q-item clickable tag="a">
            <q-item-section avatar>
              <q-icon name="school" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Étudiants</q-item-label>
              <q-item-label caption>Liste des étudiants et accès au GED</q-item-label>
            </q-item-section>
          </q-item>
          </router-link>
          <router-link to="/ged" class="router-link">
          <q-item clickable tag="a">
            <q-item-section avatar>
              <q-icon name="code" />
            </q-item-section>
            <q-item-section>
              <q-item-label>GED</q-item-label>
              <q-item-label caption>Accès aux documents du GED</q-item-label>
            </q-item-section>
          </q-item>
          </router-link>
        </div>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'LayoutDefault',

  setup () {
    // Check connection
    const checkConnected = () => {
      const token = localStorage.getItem('token')
      if (token) {
        const payload = JSON.parse(window.atob(token.split('.')[1]));
        const name = `${payload.first_name} ${payload.last_name}`
        const roles = payload.role.map(r => r[0].toUpperCase() + r.slice(1))
        return { connected: true, name, roles }
      } else {
        return { connected: false}
      }
    }

    let res = checkConnected()

    if (res.connected) {
      console.log('Connected')
      // Set default value to data
      return {
        leftDrawerOpen: ref(false),
        name: ref(res.name),
        roles: ref(res.roles),
        connected: ref(true)
      }
    } else {
      console.log('Not connected')
      return {
        leftDrawerOpen: ref(false),
        name: ref(''),
        roles: ref([]),
        connected: ref(false)
      }
    }
  },
  methods: {
    disconnect() {
      localStorage.removeItem('token')
      this.$router.push('/auth')
      this.name = ''
      this.roles = []
    },
  }
}
</script>

<style>
.router-link {
  text-decoration: none;
  color: inherit;
}
</style>