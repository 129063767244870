import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client/core'
import { logErrorMessages } from '@vue/apollo-util'
import { onError } from '@apollo/client/link/error'

// Apollo
// Set Headers
const link = {
    headers: {},
}

if (localStorage.getItem('token')) link.headers.authorization = `Bearer ${localStorage.getItem('token')}`;

const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: process.env.VUE_APP_DEV === 'true' ? process.env.VUE_APP_LOCAL_HASURA_GRAPHQL_ENDPOINT : process.env.VUE_APP_DEPLOY_HASURA_GRAPHQL_ENDPOINT,
    ...link,
})

const errorLink = onError((error) => {
    logErrorMessages(error)
})

const additiveLink = from([errorLink, httpLink])

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
    link: additiveLink,
    cache,
})

export { apolloClient }